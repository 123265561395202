.color-fields-container {
  display: flex;
  flex-direction: row !important;
  /* Set the direction to horizontal */
  gap: 10px;
  /* Adjust the gap between color fields as needed */
}


.color-field {
  flex: 1;
  /* Each color field takes up equal space */
  width: 180px;
  /* Adjust the max-width as needed */
}